import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Button from "react-bootstrap/esm/Button";
import Logo from "../../Assets/Images/logo.webp";
import MobLogo from "../../Assets/Images/MobLogo.webp";

import "./Header.css";
import { Link } from "react-router-dom";
import { useState } from "react";
import { NavLink } from "react-router-dom";
import { useEffect } from "react";

import React from "react";

import Lottie from "react-lottie";

// import Message1 from '../../Assets/Website Assets/Animations/New/Message1.json'
import Message1 from "../../Assets/Website Assets/Animations/New/Chat smile.json";

const CustomNavDropdown = ({ children }) => {
  const [open, setOpen] = useState(false);
  const [anyItemActive, setAnyItemActive] = useState(false);

  const handleDropdownToggle = () => {
    setOpen(!open);
  };

  const handleDropdownItemClick = () => {
    // setAnyItemActive(true);
    setOpen(false); // Close the dropdown when a dropdown item is clicked
  };

  return (
    <div
      className={`nav-item dropdown ${open ? "show" : ""} ${
        anyItemActive ? "any-item-active" : ""
      }`}
    >
      <button
        className="dropdown-toggle nav-link"
        type="button"
        id="basic-nav-dropdown"
        onClick={handleDropdownToggle}
        aria-expanded={open}
        style={{
          border: "none",
          paddingRight: "1rem",
          background: "none !important",
        }}
      >
        Portfolio
      </button>
      <div
        className={`dropdown-menu ${open ? "show" : ""}`}
        aria-labelledby="basic-nav-dropdown"
        data-bs-popper="static"
      >
        {React.Children.map(children, (child) =>
          React.cloneElement(child, { onClick: handleDropdownItemClick })
        )}
      </div>
    </div>
  );
};

export const Header = () => {
  const styleObject = { width: "20vw", height: "8vh" };

  const [expanded, setExpanded] = useState(false);

  const handleToggle = () => {
    setExpanded(!expanded);
  };

  const handleDropdownItemClick = () => {
    // Close the dropdown when any nav item is clicked
    const dropdownMenu = document.querySelector(".dropdown-menu.show");
    if (dropdownMenu) {
      dropdownMenu.classList.remove("show");
    }
  };

  const Anim1 = {
    loop: true,
    autoplay: true,
    animationData: Message1, // Pass the imported animation data
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div className="header">
      <Navbar
        className="Lblock"
        expand="md"
        expanded={expanded}
        variant="light"
      >
        <Container>
          <Navbar.Brand as={Link} to="/">
            <img
              src={Logo}
              alt="Logo"
              className="Lblock"
              onClick={handleDropdownItemClick}
            />
          </Navbar.Brand>

          <Navbar.Toggle
            aria-controls="basic-navbar-nav"
            onClick={handleToggle}
          />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav>
              <CustomNavDropdown>
                <NavDropdown.Item
                  as={NavLink}
                  to="/games"
                  className="drop-item"
                  onClick={handleDropdownItemClick}
                >
                  Games
                </NavDropdown.Item>
                <NavDropdown.Item
                  as={NavLink}
                  to="/Apps"
                  className="drop-item"
                  onClick={handleDropdownItemClick}
                >
                  Apps
                </NavDropdown.Item>
              </CustomNavDropdown>
              <Nav.Link
                as={NavLink}
                to="/services"
                onClick={handleDropdownItemClick}
              >
                Services
              </Nav.Link>
              <Nav.Link
                as={NavLink}
                to="/about"
                onClick={handleDropdownItemClick}
              >
                About
              </Nav.Link>
              <Nav.Link
                as={NavLink}
                to="/career"
                onClick={handleDropdownItemClick}
              >
                Career
              </Nav.Link>
              <Nav.Link
                as={NavLink}
                to="/lifeatmobify"
                onClick={handleDropdownItemClick}
              >
                Life at Mobify
              </Nav.Link>
            </Nav>

            <Nav>
              <Button
                className="header-button"
                as={Link}
                to="/contact"
                onClick={handleDropdownItemClick}
              >
                Contact Us
              </Button>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <Navbar
        className="Mblock"
        expand="lg"
        expanded={expanded}
        variant="light"
      >
        <Container>
          <Navbar.Brand as={Link} to="/">
            <img src={MobLogo} alt="Logo" className="Mblock" />
          </Navbar.Brand>

          <Nav>
            <Lottie className="hero" options={Anim1} style={styleObject} />
          </Nav>
        </Container>
      </Navbar>
    </div>
  );
};

// Get all navigation items
var navItems = document.querySelectorAll(".nav-item");

// Loop through each navigation item
navItems.forEach(function (item) {
  // Add event listener for click event
  item.addEventListener("click", function () {
    // Remove active class from all navigation items
    navItems.forEach(function (navItem) {
      navItem.classList.remove("active");
    });
    // Add active class to the clicked navigation item
    item.classList.add("active");
  });
});
