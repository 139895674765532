import React, { useState } from "react";
import { render } from "react-dom";
import "./Mobify.css";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Lottie from "react-lottie";
import { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

import LightGallery from "lightgallery/react";
import lgZoom from "lightgallery/plugins/zoom";
import lgVideo from "lightgallery/plugins/video";
import fjGallery from "flickr-justified-gallery";

import MobifyLifeAnim from "../../Assets/Website Assets/Animations/New/Life In Mobify.json";

import sphere1 from "../../Assets/Website Assets/Life at Mobify/sphere1.webp";
import sphere2 from "../../Assets/Website Assets/Life at Mobify/sphere2.webp";
import sphere3 from "../../Assets/Website Assets/Life at Mobify/sphere3.webp";
import sphere4 from "../../Assets/Website Assets/Life at Mobify/sphere4.webp";

import ContactM1 from "../../Assets/Website Assets/Contact Us/ContactM1.webp";
import ContactM2 from "../../Assets/Website Assets/Contact Us/ContactM2.webp";

import { useMediaQuery } from "@mui/material";
import axios from "axios";

function srcset(image, size, rows = 1, cols = 1) {
  return {
    src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
    srcSet: `${image}?w=${size * cols}&h=${
      size * rows
    }&fit=crop&auto=format&dpr=2 2x`,
  };
}

const aosAnimations = [
  "fade-up",
  "fade-down",
  "fade-left",
  "fade-right",
  "zoom-in",
  "zoom-out",
];

export const Mobifylife = () => {
  useEffect(() => {
    document.title = "Life at Mobify";
    document
      .querySelector('meta[name="title"]')
      .setAttribute("content", "Life at Mobify | Mobify");
    document
      .querySelector('meta[name="description"]')
      .setAttribute(
        "content",
        "Experience the Thrill of Creating Digital Wonders, Discover the moments that make us."
      );
    document
      .querySelector('meta[name="keywords"]')
      .setAttribute(
        "content",
        "mobify, mobify islamabad, game development, app development"
      );
  }, []);

  const getRandomAosAnimation = () =>
    aosAnimations[Math.floor(Math.random() * aosAnimations.length)];

  useEffect(() => {
    AOS.init({
      duration: 1000,
      easing: "ease",
      once: true,
    });
  }, []);

  // Don't initialize fjGallery in useEffect as we're using ImageList for mobile view
  // This will avoid conflicts between different gallery types

  const Anim1 = {
    loop: true,
    autoplay: true,
    animationData: MobifyLifeAnim,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const isMobile = useMediaQuery("(max-width:600px)");
  const cols = isMobile ? 2 : 4; // Use 2 columns for mobile grid layout
  const API_URL = process.env.REACT_APP_API_URL;
  const [itemData, setItemData] = useState([]);

  useEffect(() => {
    const fetchMobifyLifeData = async () => {
      try {
        const response = await axios.get(`${API_URL}/api/mobify/life/`);
        const results = response.data.results;

        const transformedData = results.map((item) => ({
          img: `${API_URL}${item.image}`,
          title: "Mobify",
          rows: (item.image_dimension === "square" || item.image_dimension === "landscape") ? 1 : 2,
          cols: item.image_dimension === "potrait" ? 2 : 1,
        }));

        setItemData(transformedData);
      } catch (error) {
        console.error("Error fetching Mobify Life data:", error);
      }
    };

    fetchMobifyLifeData();
  }, []);

  return (
    <div className="mobifylife">
      <div className="div1">
        <Container fluid>
          <img src={sphere1} className="sphere1 Lblock" alt="" />
          <img src={sphere2} className="sphere2 Lblock" alt="" />
          <img src={sphere3} className="sphere3 Lblock" alt="" />
          <img src={sphere4} className="sphere4 Lblock" alt="" />
          <img src={ContactM1} className="sphere1 Mblock" alt="" />
          <img src={ContactM2} className="sphere2 Mblock" alt="" />

          <Row>
            <Col className="Mblock" xs={12}>
              <h1>Discover the moments that make us</h1>
            </Col>
          </Row>

          <Row>
            <Col className="d-flex justify-content-center LAnim" xs={12}>
              <Lottie
                className="hero"
                options={Anim1}
                style={{ width: "62vw", height: "auto" }}
              />
            </Col>
            <Col sm={12} md={6} className="col MAnim">
              <Lottie
                className="hero"
                options={Anim1}
                style={{ width: "88vw", height: "auto" }}
              />
            </Col>
          </Row>
        </Container>
      </div>

      <div className="div2">
        <Container>
          <h2 className="Lblock" data-aos="fade-up">
            Experience the Thrill of Creating Digital Wonders, Discover the
            moments that make us
          </h2>
          
          {/* Desktop view with quilted ImageList */}
          <div className="Lblock">
            <ImageList
              sx={{ width: "100%", height: "auto" }}
              variant="quilted"
              cols={4}
              rowHeight={336}
            >
              {itemData.map((item, idx) => (
                <ImageListItem
                  key={idx}
                  cols={item.cols || 1}
                  rows={item.rows || 1}
                  data-aos="fade-up"
                >
                  <img
                    src={item.img}
                    alt={item.title}
                    loading="lazy"
                    style={{
                      objectFit: "cover",
                      width: "100%",
                      height: "100%",
                    }}
                  />
                </ImageListItem>
              ))}
            </ImageList>
          </div>
          
          {/* Mobile view with grid-style ImageList */}
          <div className="Mblock">
            <ImageList
              sx={{ width: "100%", height: "auto", overflow: "hidden" }}
              cols={2}
              gap={8}
              rowHeight={160}
            >
              {itemData.map((item, idx) => (
                <ImageListItem key={idx}>
                  <img
                    src={item.img}
                    alt={item.title}
                    loading="lazy"
                    style={{
                      objectFit: "cover",
                      width: "100%",
                      height: "100%",
                      borderRadius: "4px",
                    }}
                   
                  />
                </ImageListItem>
              ))}
            </ImageList>
          </div>
        </Container>
      </div>
    </div>
  );
};