import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Lottie from "react-lottie";
import { Carousel } from "react-bootstrap";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs"; // Import arrow icons
import AOS from "aos";
import "aos/dist/aos.css"; // You can also use <link> for styles

import "./Portfolio.css";

// import LogoAppsM from '../../Assets/Website Assets/Apps/AppsTextM.webp'
import LogoAppsM from "../../Assets/Website Assets/Apps/AppsTextM.webp";
import LogoSvg from "../../Assets/Website Assets/Apps/Apps-01 1.svg";

import App1 from "../../Assets/Website Assets/Apps/App1.webp";
import App2 from "../../Assets/Website Assets/Apps/App2.webp";
import App3 from "../../Assets/Website Assets/Apps/App3.webp";

import App4 from "../../Assets/Website Assets/Apps/App4.webp";
import App5 from "../../Assets/Website Assets/Apps/App5.webp";
import App6 from "../../Assets/Website Assets/Apps/App6.webp";

import App7 from "../../Assets/Website Assets/Apps/App7.webp";
import App8 from "../../Assets/Website Assets/Apps/App8.webp";
import App9 from "../../Assets/Website Assets/Apps/App9.webp";

import AppAnim from "../../Assets/Website Assets/Animations/New/Apps new.json";

import bannerImage from "../../Assets/Website Assets/Games/Games Main Banner.webp"; // Replace 'games-main-banner.jpg' with your actual image file

import Mbanner from "../../Assets/Website Assets/Games/GamesBackM.webp";

// Div2 Images
import Div2Img1 from "../../Assets/Website Assets/Apps/Sphere1.webp";
import Div2Img2 from "../../Assets/Website Assets/Apps/Sphere2.webp";
import Div2Img3 from "../../Assets/Website Assets/Apps/Sphere3.webp";
import Div2Img4 from "../../Assets/Website Assets/Apps/Sphere4.webp";

import obj1 from "../../Assets/Website Assets/Apps/obj1.webp";
import obj2 from "../../Assets/Website Assets/Apps/obj2.webp";
import obj3 from "../../Assets/Website Assets/Apps/obj3.webp";

import play from "../../Assets/Website Assets/Home/Play.webp";
import ios from "../../Assets/Website Assets/Home/ios1.webp";

import AsNavFor from "./PortfolioSlider";


// Downloads icon

import DownloadIcon from "../../Assets/Website Assets/Games/Games/DownloadIcon.webp";
import axios from "axios";

const images = [
  {
    image: App1,
    button1Link:
      "https://play.google.com/store/apps/details?id=com.xenstudio.android.love.animated.photo.frame.editor",
  },
  {
    image: App2,
    button1Link:
      "https://play.google.com/store/apps/details?id=com.xenstudio.books.photo.frame.collage",
  },
  {
    image: App3,
    button1Link:
      "https://play.google.com/store/apps/details?id=com.frameme.photoeditor.collagemaker.effects",
  },
  {
    image: App4,
    button1Link:
      "https://play.google.com/store/apps/details?id=com.xilli.collagemaker.photoeffects.photoeditor",
  },
  {
    image: App5,
    button1Link:
      "https://play.google.com/store/apps/details?id=com.xenstudio.birthdaycake.photoframe&hl=en", // to change photo on cake link
  },
  {
    image: App6,
    button1Link:
      "https://play.google.com/store/apps/details?id=com.xenstudio.romantic.love.photoframe",
  },
  {
    image: App7,
    button1Link:
      "https://play.google.com/store/apps/details?id=com.xenstudios.allformate.videoplay.hdvideoplayer",
  },
  {
    image: App8,
    button1Link:
      "https://play.google.com/store/apps/details?id=com.xenstudio.secure.swift.vpnproxy.android",
  },
  {
    image: App9,
    button1Link:
      "https://play.google.com/store/apps/details?id=com.xenstudio.romantic.love.photoframe&hl=en",
  },
]; // Define your image paths array here


const RandomColumns = ({ images }) => {
  const [clickedIndex, setClickedIndex] = useState(null);

  const handleImageClick = (index) => {
    // Toggle the state to show/hide buttons
    setClickedIndex(index === clickedIndex ? null : index);
  };

  const [randomIndices, setRandomIndices] = useState([]);
  const [hoveredIndex, setHoveredIndex] = useState(null);

  const handleMouseEnter = (index) => {
    setHoveredIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredIndex(null);
  };

  // Generate random order of indices for all 9 images
  const generateRandomIndices = () => {
    const indices = [...Array(9).keys()]; // Create an array of indices [0, 1, 2, ..., 8]
    indices.sort(() => Math.random() - 0.5); // Shuffle the indices array randomly
    return indices;
  };

  useEffect(() => {
    const indices = generateRandomIndices();
    setRandomIndices(indices);
  }, []); // Run only once on component mount

  // Split the shuffled indices into three groups for three rows
  const rows = Array.from({ length: 3 }, (_, rowIndex) =>
    randomIndices.slice(rowIndex * 3, (rowIndex + 1) * 3)
  );
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  console.log(projects, "projects");

  // .env file
  const API_URL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        setLoading(true);
        const response = await axios.get(
          `${API_URL}/api/dashboard/portfolios?device=desktop`
        );
        console.log("API Response:", response.data);

        // Filter data based on category
        const apps = response.data.results.filter(
          (project) => project.section === "apps"
        );
        console.log(apps, "apps");
        setProjects(apps);
        setLoading(false);
      } catch (err) {
        setError("Failed to fetch projects");
        setLoading(false);
        console.error("Error fetching projects:", err);
      }
    };

    fetchProjects();

    // Add window resize listener
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="random-columns">
      {projects.length > 0 ? (
        <Row className="mb-3">
          {projects.map((project, index) => (
            <Col
              key={index}
              md={4}
              className="image-container"
              data-aos="fade-up"
            >
              <div
                className="position-relative"
                onMouseEnter={() => handleMouseEnter(index)}
                onMouseLeave={handleMouseLeave}
              >
                <img
                  src={
                    project.image?.startsWith("/")
                      ? `${API_URL}${project.image}`
                      : project.image || "fallback-image.jpg"
                  }
                  alt={project.name}
                  className="img-fluid"
                />
                <div className="image-overlay"></div>
                {hoveredIndex === index && (
                  <div className="button-container">
                    {project.project_android_url && (
                      <a
                        href={project.project_android_url}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img src={play} alt="Play Store" />
                      </a>
                    )}
                    {project.project_ios_url && (
                      <a
                        href={project.project_ios_url}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img src={ios} alt="App Store" />
                      </a>
                    )}
                  </div>
                )}
              </div>
            </Col>
          ))}
        </Row>
      ) : (
        <p>Loading projects...</p>
      )}

      {/* Mobile Carousel */}
      {projects.length > 0 && window.innerWidth < 576 && (
        <Carousel
          nextIcon={<BsChevronRight className="carousel-control-icon" />}
          prevIcon={<BsChevronLeft className="carousel-control-icon" />}
          indicators={false}
        >
          {projects.map((project, index) => (
            <Carousel.Item key={index}>
              <div
                className="image-containerM"
                onClick={() => handleImageClick(index)}
              >
                <img
                  src={
                    project.image?.startsWith("/")
                      ? `${API_URL}${project.image}`
                      : project.image || "fallback-image.jpg"
                  }
                  alt={project.name}
                  className="img-fluid"
                />
                {clickedIndex === index && (
                  <div className="button-container">
                    {project.url && (
                      <a href={project.url} target="_blank" rel="noreferrer">
                        <img src={play} alt="Play Store" />
                      </a>
                    )}
                    {project.url_ios && (
                      <a
                        href={project.url_ios}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img src={ios} alt="App Store" />
                      </a>
                    )}
                  </div>
                )}
              </div>
            </Carousel.Item>
          ))}
        </Carousel>
      )}
    </div>
  );
};

export const Apps = () => {
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const fetchProjects = async () => {
      try {
        setLoading(true);
        const response = await axios.get(
          `${API_URL}/api/dashboard/portfolios?device=mobile`
        );
        console.log("API Response:", response.data);

        // Filter data based on category
        const apps = response.data.results.filter(
          (project) => project.section === "apps"
        );
        console.log(apps, "apps");
        setProjects(apps);
        setLoading(false);
      } catch (err) {
        setError("Failed to fetch projects");
        setLoading(false);
        console.error("Error fetching projects:", err);
      }
    };

    fetchProjects();

    // Add window resize listener
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const API_URL = process.env.REACT_APP_API_URL;
  const formattedMobileImages = projects.map((project) => ({
    image: project.image?.startsWith("/")
      ? `${API_URL}${project.image}`
      : project.image,
    thumbnail: project.thumbnail?.startsWith("/")
      ? `${API_URL}${project.thumbnail}`
      : project.thumbnail,
    icon: project.project_icon,
    title: project.project_name,
    Downloads: project.download,
    thumbnailtext: project.project_name,
    buttonAndroidLink: project.project_android_url || "#",
    
  }));

  // In your React component
  useEffect(() => {
    document.title = "APPs"; // Set your desired page title here
    document
      .querySelector('meta[name="title"]')
      .setAttribute("content", "APPs | Mobify");
    document
      .querySelector('meta[name="description"]')
      .setAttribute(
        "content",
        "Apps that makes a difference beyond imaginations beyond limits."
      );
    document
      .querySelector('meta[name="keywords"]')
      .setAttribute(
        "content",
        "mobify, mobify islamabad, game development, app development"
      );
  }, []);

  // Add event listener to initialize AOS when the component mounts
  useEffect(() => {
    AOS.init({
      once: true, // Ensure animation only occurs once
      offset: 270, // Adjust offset as needed to trigger animation earlier/later
      // disable: 'mobile', // Disable on mobile devices if needed
      startEvent: "scroll", // Trigger animation on scroll event
      useClassNames: true, // Apply class names to elements
      initClassName: "aos-init", // Class applied during initialization
      animatedClassName: "aos-animate", // Class applied on animation
      // duration: 1000, // Duration of animations in milliseconds
      easing: "ease", // Easing function for animations
    });
  }, []);

  const [rotation, setRotation] = useState(180);

  useEffect(() => {
    setTimeout(() => {
      setRotation(0); // Update the rotation to 0 degrees after a delay
    }, 1000); // Adjust the delay as needed
  }, []); // Run once after the component mounts

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: AppAnim, // Pass the imported animation data
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div className="portfolio apps  AppsDiv">
      <div className="div1 apps ">
        <img src={Mbanner} className="Mblock portfolioBanner" alt="" />

        <img src={obj1} className="obj1 Lblock" alt="" />
        <img src={obj2} className="obj2 Lblock" alt="" />
        <img src={obj3} className="obj3 Lblock" alt="" />

        <img
          src={bannerImage}
          alt="Games Main Banner"
          className="banner-image"
          style={{ transform: `rotate(${rotation}deg)` }} // Apply the rotation dynamically
        />

        <Container fluid>
          <Row className="justify-content-center">
            <Col xs={12} md={5} lg={5} className="col Lblock portfoliologo">
              <img
                className="gameImage"
                src={LogoAppsM}
                loading="eager"
                alt=""
              />
            </Col>
            <Col xs={12} lg={6} className="col Mblock">
              <img className="gameImage" src={LogoSvg} loading="eager" alt="" />
            </Col>
            <Col xs={12} md={6} lg={6} className="appDiv col">
              {/* <Lottie className='Lottie_Anim' options={defaultOptions} height={749} width={967} /> */}

              <Col xs={12} md={6} lg={6} className="animDiv col Lblock">
                <Lottie
                  className="Lottie_Anim"
                  options={defaultOptions}
                  style={{ width: "44vw", height: "auto" }}
                />
              </Col>

              <Col xs={12} lg={6} className="animDiv col Mblock">
                <Lottie
                  className="Lottie_Anim"
                  options={defaultOptions}
                  style={{ width: "auto", height: "56vh" }}
                />
              </Col>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="div2 ">
        {/* Static Images */}
        <img src={Div2Img1} className="Div2Img1 Lblock" alt="" />
        <img src={Div2Img2} className="Div2Img2 Lblock" alt="" />
        <img src={Div2Img3} className="Div2Img3 Lblock" alt="" />
        <img src={Div2Img4} className="Div2Img4 Lblock" alt="" />
        <Container className="Lblock">
          <RandomColumns images={images} />
          <div className="boy-container">
            {/* <img className='boy' src={Boy} alt="" /> */}
          </div>
        </Container>
        {/* carousel of large images on mobile*/}
        <div className="Mblock">
          <AsNavFor items={formattedMobileImages} />
        </div>
        {/* last part of mobile */}
        <div className="Mblock" data-aos="fade-up" data-aos-duration="1500">
          <Container style={{ width: "90%" }}>
            {projects.map((item, index) => (
              <Row key={index} className="portfolio-rows">
                <Col xs={4}>
                  <a href={item.project_android_url} target="_blank" rel="noreferrer">
                    <img  src={
                   item.project_icon?.startsWith("/")
                      ? `${API_URL}${item.project_icon}`
                      : item.project_icon || "fallback-image.jpg"
                  } alt="icon" />
                  </a>
                </Col>
                <Col xs={8}>
                  <h4>{item.project_name}</h4>
                  <h5>
                    <img src={DownloadIcon} className="DownloadIcon" alt="" />{" "}
                    {item.download}
                  </h5>
                </Col>
              </Row>
            ))}
          </Container>
        </div>
      </div>
    </div>
  );
};
