import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Lottie from "react-lottie";
import { Carousel } from "react-bootstrap";
import AOS from "aos";
import "aos/dist/aos.css"; // You can also use <link> for styles

import { BsChevronLeft, BsChevronRight } from "react-icons/bs"; // Import arrow icons

import "./Portfolio.css";

import heroM from "../../Assets/Website Assets/Games/Logo-1-02 3.svg";

// import LogoGames from '../../Assets/Website Assets/Games/LogoGames.webp'
import LogoGames from "../../Assets/Website Assets/Games/LogoGames.webp";

import Game1 from "../../Assets/Website Assets/Games/game1.webp";
import Game2 from "../../Assets/Website Assets/Games/game2.webp";
import Game3 from "../../Assets/Website Assets/Games/game3.webp";

import Game4 from "../../Assets/Website Assets/Games/game4.webp";
import Game5 from "../../Assets/Website Assets/Games/game5.webp";
import Game6 from "../../Assets/Website Assets/Games/game6.webp";

import Game7 from "../../Assets/Website Assets/Games/game7.webp";
import Game8 from "../../Assets/Website Assets/Games/game8.webp";
import Game9 from "../../Assets/Website Assets/Games/game9.webp";

import Boy from "../../Assets/Website Assets/Games/boy.webp";
import BoyAnim from "../../Assets/Website Assets/Animations/New/Game playing.json";

import bannerImage from "../../Assets/Website Assets/Games/Games Main Banner.webp"; // Replace 'games-main-banner.jpg' with your actual image file

import Sphere from "../../Assets/Website Assets/Games/Sphere.webp";

import play from "../../Assets/Website Assets/Home/Play.webp";
import ios from "../../Assets/Website Assets/Home/ios1.webp";

// Div2 Images
import Div2Img1 from "../../Assets/Website Assets/Games/Sphere1.webp";
import Div2Img2 from "../../Assets/Website Assets/Games/Sphere2.webp";
import Div2Img3 from "../../Assets/Website Assets/Games/Rect1.webp";
import Div2Img4 from "../../Assets/Website Assets/Games/Rect2.webp";

import Mbanner from "../../Assets/Website Assets/Games/GamesBackM.webp";

import AsNavFor from "./PortfolioSlider";

// images
import bus from "../../Assets/Website Assets/Games/Games/banners/bus.webp";
import bike from "../../Assets/Website Assets/Games/Games/banners/Bike Stunt Games 5M 1.webp";
import bike2 from "../../Assets/Website Assets/Games/Games/banners/GT Bike Racing 10M 1.webp";
import bike3 from "../../Assets/Website Assets/Games/Games/banners/Bike Stunt 3D 100M 1.webp";
import car from "../../Assets/Website Assets/Games/Games/banners/Real Car Race 3D 100M 1.webp";
import GunStrike from "../../Assets/Website Assets/Games/Games/banners/Shooter.webp";
import OfflineShooter from "../../Assets/Website Assets/Games/Games/banners/image 101.webp";
import RealShooter from "../../Assets/Website Assets/Games/Games/banners/Real Commando Secret Mission 100M 1.webp";
import aero from "../../Assets/Website Assets/Games/Games/banners/Airplane Game 3D 100M 1.webp";
import fashion from "../../Assets/Website Assets/Games/Games/banners/Mask group.webp";

// icons
import ibus from "../../Assets/Website Assets/Games/Games/Small icons/ibus.webp";
import istunt from "../../Assets/Website Assets/Games/Games/Small icons/Stunt Bike.webp";
import igt from "../../Assets/Website Assets/Games/Games/Small icons/image 52.webp";
import i3d from "../../Assets/Website Assets/Games/Games/Small icons/Bike Stunt 3D 100M icon 2.webp";
import icar from "../../Assets/Website Assets/Games/Games/Small icons/Real Car Race 3D 100M icon 2.webp";
import igun from "../../Assets/Website Assets/Games/Games/Small icons/Gun Strike FPS Shooting Games 50k Icon 2.webp";
import ipara from "../../Assets/Website Assets/Games/Games/Small icons/image 100.webp";
import ireal from "../../Assets/Website Assets/Games/Games/Small icons/Real Commando Secret Mission 100M icon 2.webp";
import iaero from "../../Assets/Website Assets/Games/Games/Small icons/Airplane Game 3D 100M icon 2.webp";
import ifashion from "../../Assets/Website Assets/Games/Games/Small icons/image 105.webp";

//thumbnails
import Tbus from "../../Assets/Website Assets/Games/Games/bus-thumbnail.webp";
import Tcar from "../../Assets/Website Assets/Games/Games/Slider Icons/Real Car Race 3D 100M icon 3.webp";
import Tbike from "../../Assets/Website Assets/Games/Games/Tbike.webp";
import Tbike1 from "../../Assets/Website Assets/Games/Games/Slider Icons/Bike Stunt 3D 100M icon 4.webp";
import Tbike3 from "../../Assets/Website Assets/Games/Games/Tbike3.webp";

import Tpara from "../../Assets/Website Assets/Games/Games/Tpara.webp";
import Tpara2 from "../../Assets/Website Assets/Games/Games/Slider Icons/Gun Strike FPS Shooting Games 50k Icon 3.webp";
import Tpara3 from "../../Assets/Website Assets/Games/Games/Slider Icons/Real Commando Secret Mission 100M icon 3.webp";

import Taero from "../../Assets/Website Assets/Games/Games/Slider Icons/Airplane Game 3D 100M icon 3.webp";

import Tfashion from "../../Assets/Website Assets/Games/Games/Slider Icons/image 111.webp";

//Column Images

import Csnipe from "../../Assets/Website Assets/Games/Games/Column Images/Tsnipe.webp";
import Cmoto from "../../Assets/Website Assets/Games/Games/Column Images/moto.webp";
import CGT from "../../Assets/Website Assets/Games/Games/Column Images/GT.webp";
import Cstunt from "../../Assets/Website Assets/Games/Games/Column Images/stunt.webp";
import Ctwotwo from "../../Assets/Website Assets/Games/Games/Column Images/2248.webp";

// Downloads icon

import DownloadIcon from "../../Assets/Website Assets/Games/Games/DownloadIcon.webp";
import axios from "axios";

const images = [
  {
    name: "Moto Bike",
    image: Game1,
    button1Link:
      "https://play.google.com/store/apps/details?id=com.fg.crazy.bike.traffic.racer.free",
  },
  {
    name: "Sniper 3D",
    image: Game2,
    button1Link:
      "https://play.google.com/store/apps/details?id=com.lf.sniper.gun.shooter.free.apps",
    button2Link:
      "https://apps.apple.com/ie/app/sniper-ops-gun-shooting-games/id1520334013",
  },
  {
    name: "Offline Gun Shooting",
    image: Game3,
    button1Link:
      "https://play.google.com/store/apps/details?id=com.babloo.commando.adventure.shooting",
  },
  {
    name: "Airplane Game Flight Pilot",
    image: Game4,
    button1Link:
      "https://play.google.com/store/apps/details?id=com.volcano.city.airplane.pilotflight",
  },
  {
    name: "Modern Bus Simulator",
    image: Game5,
    button1Link:
      "https://play.google.com/store/apps/details?id=com.gzl.drivebus.parking.game",
  },
  {
    name: "Modern Car Parking",
    image: Game6,
    button1Link:
      "https://play.google.com/store/apps/details?id=com.volcano.modrn.car.parking.d",
    button2Link:
      "https://apps.apple.com/ie/app/car-driving-school-car-games/id1480804805",
  },
  {
    name: "Pet Clinic Animal",
    image: Game7,
    button1Link:
      "https://play.google.com/store/apps/details?id=com.pet.clinic.animal.doctor.game",
  },
  {
    name: "Target bottle shoot",
    image: Game8,
    button1Link:
      "https://play.google.com/store/apps/details?id=com.door.target.bottle.shoot",
    button2Link:
      "https://apps.apple.com/us/app/slingshot-king-bottle-shoot/id6473723908",
  },
  {
    name: "Doctor surgeon",
    image: Game9,
    button1Link:
      "https://play.google.com/store/apps/details?id=com.sg.hospital.doctor.surgeon.free.games",
  },
  // Add more games with their respective button links
];

const mobileimages = [
  {
    image: bus,
    icon: ibus,
    thumbnail: Tbus,
    title: "Modern Bus simulator",
    thumbnailtext: "Bus Stunt",
    Downloads: "100 M+",
    button1Link:
      "https://play.google.com/store/apps/details?id=com.gzl.drivebus.parking.game",
    button2Link: "",
  },

  {
    image: bike,
    icon: istunt,
    thumbnail: Tbike,
    Downloads: "5 M+",
    title: "Bike Stunt Games",
    thumbnailtext: "Bike Stunt",
    button1Link:
      "https://play.google.com/store/apps/details?id=com.ghive.endless.car.race.traffic.drive.apps",
    button2Link: "",
  },
  {
    image: bike2,
    icon: igt,
    thumbnail: Tbike3,
    Downloads: "10 M+",
    title: "GT Bike Racing",
    thumbnailtext: "GT Bike Racing",
    button1Link:
      "https://play.google.com/store/apps/details?id=com.fg.crazy.bike.traffic.racer.free",
    button2Link: "",
  },
  {
    image: bike3,
    icon: i3d,
    thumbnail: Tbike1,
    Downloads: "100 M+",
    title: "Bike Stunt 3D",
    thumbnailtext: "Bike Stunt",
    button1Link:
      "https://play.google.com/store/apps/details?id=com.knights.bikesstunt.motomaster&pcampaignid=web_share",
    button2Link: "",
  },
  {
    image: car,
    icon: icar,
    thumbnail: Tcar,
    Downloads: "100 M+",
    title: "Real Car Race",
    thumbnailtext: "Real Car Race",
    button1Link:
      "https://play.google.com/store/apps/details?id=com.gamexis.racing.ferocity.apps",
    button2Link: "",
  },

  {
    image: GunStrike,
    icon: igun,
    thumbnail: Tpara2,
    Downloads: "50 K+",
    title: "Gun Strike FPS Shooting",
    thumbnailtext: "Gun Strike FPS",
    button1Link:
      "https://play.google.com/store/apps/details?id=com.aimbit.cf.fps.gun.shooting.game",
    button2Link: "",
  },

  {
    image: OfflineShooter,
    icon: ipara,
    thumbnail: Tpara,
    Downloads: "10 M+",
    title: "Offline Gun Shooting",
    thumbnailtext: "Offline Gun",
    button1Link:
      "https://play.google.com/store/apps/details?id=com.babloo.commando.adventure.shooting",
    button2Link: "",
  },

  {
    image: RealShooter,
    icon: ireal,
    thumbnail: Tpara3,
    Downloads: "10 M+",
    title: "Real Commando",
    thumbnailtext: "Real Commando",
    button1Link:
      "https://play.google.com/store/apps/details?id=com.fun.games.commando.black.shadow",
    button2Link: "",
  },

  {
    image: aero,
    icon: iaero,
    thumbnail: Taero,
    Downloads: "100 M+",
    title: "Airplane Game 3D",
    thumbnailtext: "Airplane Game",
    button1Link:
      "https://play.google.com/store/apps/details?id=com.volcano.city.airplane.pilotflight&hl=en",
    button2Link: "",
  },
  {
    image: fashion,
    icon: ifashion,
    thumbnail: Tfashion,
    Downloads: "5 M+",
    title: "Fashion Game",
    thumbnailtext: "Fashion Game",
    button1Link:
      "https://play.google.com/store/apps/details?id=com.sg.fashion.makeover.dressup.game",
    button2Link: "",
  },

  // Add more games with their respective button links
];

const columnimages = [
  {
    image: bus,
    thumbnail: Csnipe,
    Downloads: "50 M+",
    title: "3D Gun Shooting",
    button1Link:
      "https://play.google.com/store/apps/details?id=com.lf.sniper.gun.shooter.free.apps&hl=en",
  },

  {
    image: bus,
    thumbnail: Cmoto,
    Downloads: "5 M+",
    title: "Motto Attack Race",
    button1Link:
      "https://play.google.com/store/apps/details?id=com.newera.death.racing.gang.stunt.moto&hl=en",
  },

  {
    image: bus,
    thumbnail: CGT,
    Downloads: "10 M+",
    title: "Moto World Tour",
    button1Link:
      "https://play.google.com/store/apps/details?id=com.kn.trafficracer.bikeracegames&hl=en",
  },

  {
    image: bus,
    thumbnail: Cstunt,
    Downloads: "10 M+",
    title: "Sling King",
    button1Link:
      "https://play.google.com/store/apps/details?id=com.door.target.bottle.shoot",
  },

  {
    image: bus,
    thumbnail: Ctwotwo,
    title: "2248 King",
    Downloads: "1 k+",
    button1Link:
      "https://play.google.com/store/apps/details?id=com.two.two.four.eight.match.numbers.games",
  },

  // Add more games with their respective button links
];

const RandomColumns = ({ images }) => {
  const [clickedIndex, setClickedIndex] = useState(null);

  const handleImageClick = (index) => {
    // Toggle the state to show/hide buttons
    setClickedIndex(index === clickedIndex ? null : index);
  };

  const [randomIndices, setRandomIndices] = useState([]);
  const [hoveredIndex, setHoveredIndex] = useState(null);

  // Generate random order of indices for all 9 images
  const generateRandomIndices = () => {
    const indices = [...Array(9).keys()]; // Create an array of indices [0, 1, 2, ..., 8]
    indices.sort(() => Math.random() - 0.5); // Shuffle the indices array randomly
    return indices;
  };

  useEffect(() => {
    const indices = generateRandomIndices();
    setRandomIndices(indices);
  }, []); // Run only once on component mount

  const handleMouseEnter = (index) => {
    setHoveredIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredIndex(null);
  };

  // Split the shuffled indices into three groups for three rows
  const rows = Array.from({ length: 3 }, (_, rowIndex) =>
    randomIndices.slice(rowIndex * 3, (rowIndex + 1) * 3)
  );
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  console.log(projects, "projects");

  const API_URL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        setLoading(true);
        const response = await axios.get(
          `${API_URL}/api/dashboard/portfolios?device=desktop`
        );
        console.log("API Response:", response.data);

        // Filter data based on category
        const games = response.data.results.filter(
          (project) => project.section === "games"
        );

        setProjects(games);
        setLoading(false);
      } catch (err) {
        setError("Failed to fetch projects");
        setLoading(false);
        console.error("Error fetching projects:", err);
      }
    };

    fetchProjects();

    // Add window resize listener
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <div className="random-columns">
      {projects.length > 0 ? (
        <Row className="mb-3">
          {projects.map((project, index) => (
            <Col
              key={index}
              md={4}
              className="image-container"
              data-aos="fade-up"
            >
              <div
                className="position-relative"
                onMouseEnter={() => handleMouseEnter(index)}
                onMouseLeave={handleMouseLeave}
              >
                <img
                  src={
                    project.image?.startsWith("/")
                      ? `${API_URL}${project.image}`
                      : project.image || "fallback-image.jpg"
                  }
                  alt={project.name}
                  className="img-fluid"
                />
                <div className="image-overlay"></div>
                {hoveredIndex === index && (
                  <div className="button-container">
                    {project.project_android_url && (
                      <a
                        href={project.project_android_url}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img src={play} alt="Play Store" />
                      </a>
                    )}
                    {project.project_ios_url && (
                      <a
                        href={project.project_ios_url}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img src={ios} alt="App Store" />
                      </a>
                    )}
                  </div>
                )}
              </div>
            </Col>
          ))}
        </Row>
      ) : (
        <p>Loading projects...</p>
      )}

      {/* Mobile Carousel */}
      {projects.length > 0 && window.innerWidth < 576 && (
        <Carousel
          nextIcon={<BsChevronRight className="carousel-control-icon" />}
          prevIcon={<BsChevronLeft className="carousel-control-icon" />}
          indicators={false}
        >
          {projects.map((project, index) => (
            <Carousel.Item key={index}>
              <div
                className="image-containerM"
                onClick={() => handleImageClick(index)}
              >
                <img
                  src={
                    project.image?.startsWith("/")
                      ? `${API_URL}${project.image}`
                      : project.image || "fallback-image.jpg"
                  }
                  alt={project.name}
                  className="img-fluid"
                />
                {clickedIndex === index && (
                  <div className="button-container">
                    {project.project_android_url && (
                      <a
                        href={project.project_android_url}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img src={play} alt="Play Store" />
                      </a>
                    )}
                    {project.project_ios_url && (
                      <a
                        href={project.project_ios_url}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img src={ios} alt="App Store" />
                      </a>
                    )}
                  </div>
                )}
              </div>
            </Carousel.Item>
          ))}
        </Carousel>
      )}
    </div>
  );
};

export const Games = () => {
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const fetchProjects = async () => {
      try {
        setLoading(true);
        const response = await axios.get(
          `${API_URL}/api/dashboard/portfolios?device=mobile`
        );
        console.log("API Response:", response.data);

        // Filter data based on category
        const games = response.data.results.filter(
          (project) => project.section === "games"
        );

        setProjects(games);
        setLoading(false);
      } catch (err) {
        setError("Failed to fetch projects");
        setLoading(false);
        console.error("Error fetching projects:", err);
      }
    };

    fetchProjects();

    // Add window resize listener
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const API_URL = process.env.REACT_APP_API_URL;
  const formattedMobileImages = projects.map((project) => ({
    image: project.image?.startsWith("/")
      ? `${API_URL}${project.image}`
      : project.image,
    thumbnail: project.thumbnail?.startsWith("/")
      ? `${API_URL}${project.thumbnail}`
      : project.thumbnail,
    icon: project.project_icon,
    title: project.project_name,
    Downloads: project.download,
    thumbnailtext: project.project_name,
    buttonAndroidLink: project.project_android_url || "#",
    buttonAndroidLink2: project.project_ios_url || "#",
  }));

  // In your React component
  useEffect(() => {
    document.title = "Games"; // Set your desired page title here
    document
      .querySelector('meta[name="title"]')
      .setAttribute("content", "Games | Mobify");
    document
      .querySelector('meta[name="description"]')
      .setAttribute(
        "content",
        "We craft most memorable experiences beyond pixels beyond play."
      );
    document
      .querySelector('meta[name="keywords"]')
      .setAttribute(
        "content",
        "mobify, mobify islamabad, game development, app development"
      );
  }, []);

  // Add event listener to initialize AOS when the component mounts
  useEffect(() => {
    AOS.init({
      once: true, // Ensure animation only occurs once
      offset: 270, // Adjust offset as needed to trigger animation earlier/later
      // disable: 'mobile', // Disable on mobile devices if needed
      startEvent: "scroll", // Trigger animation on scroll event
      useClassNames: true, // Apply class names to elements
      initClassName: "aos-init", // Class applied during initialization
      animatedClassName: "aos-animate", // Class applied on animation
      // duration: 1000, // Duration of animations in milliseconds
      easing: "ease", // Easing function for animations
    });
  }, []);

  const [rotation, setRotation] = useState(180);

  useEffect(() => {
    setTimeout(() => {
      setRotation(0); // Update the rotation to 0 degrees after a delay
    }, 1000); // Adjust the delay as needed
  }, []); // Run once after the component mounts

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: BoyAnim, // Pass the imported animation data
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const handleMouseMove = (e) => {
    const clientX = e.clientX;
    const boy = document.querySelector(".boy");
    if (boy) {
      const windowWidth = window.innerWidth;
      const boyWidth = boy.offsetWidth;

      let maxX;
      // Adjust maxX based on device width
      if (windowWidth < 768) {
        // Adjust this threshold as needed for your specific mobile breakpoint
        // Adjust maxX for mobile devices
        maxX = windowWidth + 1700; // You might want to add some padding or margins here if necessary
      } else {
        // Use the original maxX calculation for desktop
        maxX = windowWidth - boyWidth;
      }
      const minX = -300;
      const movementFactor = 0.6; // Adjust this value to control the speed (lower value = slower movement)
      const x = Math.min(
        maxX,
        Math.max(minX, (clientX - boyWidth / 2) * movementFactor)
      ); // Ensure boy stays within window boundaries
      boy.style.left = `${x}px`; // Set boy's left position
    }
  };

  return (
    <div className="portfolio">
      <div className="div1 games">
        <img src={Mbanner} className="Mblock portfolioBanner" alt="" />

        <img
          src={bannerImage}
          alt="Games Main Banner"
          className="banner-image"
          style={{ transform: `rotate(${rotation}deg)` }} // Apply the rotation dynamically
        />

        <img src={Sphere} className="sphere Lblock" alt="" />

        <Container fluid>
          <Row className="justify-content-center">
            <Col xs={12} md={5} className="col Lblock portfoliologo">
              <img
                className="gameImage"
                src={LogoGames}
                loading="eager"
                alt=""
              />
            </Col>
            <Col xs={12} md={5} className="col s-img Mblock">
              <img
                src={heroM}
                className="anime service-img"
                loading="eager"
                alt=""
              />
            </Col>
            <Col xs={12} md={6} lg={6} className="animDiv col Lblock">
              <Lottie
                className="Lottie_Anim"
                options={defaultOptions}
                style={{ height: "auto", width: "40vw" }}
              />
            </Col>
            <Col xs={12} md={6} className="animDiv col Mblock">
              <Lottie
                className="Lottie_Anim"
                options={defaultOptions}
                width="100%"
              />
            </Col>
          </Row>
        </Container>
      </div>
      <div className="div2" data-aos="fade-up" data-aos-duration="1500">
        {/* Static Images */}
        <img src={Div2Img1} className="Div2Img1 Lblock" alt="" />
        <img src={Div2Img2} className="Div2Img2 Lblock" alt="" />
        <img src={Div2Img3} className="Div2Img3 Lblock" alt="" />
        <img src={Div2Img4} className="Div2Img4 Lblock" alt="" />
        <Container className="Lblock">
          <RandomColumns images={images} />
          <div className="boy-container" onMouseMove={handleMouseMove}>
            <img className="boy" src={Boy} alt="" />
          </div>
        </Container>

        <div className="Mblock">
          <AsNavFor items={formattedMobileImages} />
        </div>

        <div className="Mblock" data-aos="fade-up" data-aos-duration="1500">
          <Container style={{ width: "90%" }}>
            {projects.map((item, index) => (
              <Row key={index} className="portfolio-rows">
                <Col xs={4}>
                  <a href={item.project_android_url} target="_blank" rel="noreferrer">
                    <img
                      src={
                        item.project_icon?.startsWith("/")
                          ? `${API_URL}${item.project_icon}`
                          : item.project_icon || "fallback-image.jpg"
                      }
                      alt="icon"
                    />
                  </a>
                </Col>
                <Col xs={8}>
                  <h4>{item.project_name}</h4>
                  <h5>
                    <img src={DownloadIcon} className="DownloadIcon" alt="" />{" "}
                    {item.download}
                  </h5>
                </Col>
              </Row>
              
            ))}
              
          </Container>
        </div>
      </div>
    </div>
  );
};
