import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const PageDescription = ({ description }) => {
  const location = useLocation();

  useEffect(() => {
    const metaDescription = document.querySelector('meta[name="description"]');
    if (metaDescription) {
      metaDescription.setAttribute('content', description);
    } else {
      const newMetaTag = document.createElement('meta');
      newMetaTag.setAttribute('name', 'description');
      newMetaTag.setAttribute('content', description);
      document.head.appendChild(newMetaTag);
    }
  }, [location, description]);

  return null;
};

export default PageDescription;
