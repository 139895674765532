import { Carousel, Card, Row, Col, Button } from "react-bootstrap";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs"; // Import arrow icons

const CustomCarousel = ({ items }) => {
  return (
    <div className="custom-carousel-container">
      <Carousel
        nextIcon={<BsChevronRight className="carousel-control-icon" />}
        prevIcon={<BsChevronLeft className="carousel-control-icon" />}
        indicators={false}
      >
        {items.map((item, index) => (
          <Carousel.Item key={index}>
            <Card>
              <Card.Body>
                <Row>
                  <Col xs={3}>
                    <Card.Img variant="top" src={item.image} />
                  </Col>
                  <Col xs={8}>
                    <div>
                      <Card.Title>{item.title}</Card.Title>
                    </div>
                  </Col>
                </Row>
                <Card.Text>{item.text}</Card.Text>
                <Row>
                  <Col xs={5}>
                    <hr className="card-hr" />
                  </Col>
                  <Col xs={7}>
                    <Button variant="primary" onClick={item.onClick}>
                      Read More
                    </Button>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Carousel.Item>
        ))}
      </Carousel>
    </div>
  );
};

export default CustomCarousel;
