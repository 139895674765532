import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import './App.css';
import { Footer } from './Components/Pages/Footer/Footer';
import { Header } from './Components/Pages/Header/Header';
import { Home } from './Components/Pages/Home/Home'
import { Games } from './Components/Pages/Portfolio/Games';
import { Apps } from './Components/Pages/Portfolio/Apps';
import { Services } from './Components/Pages/Services/Services'
import { About } from './Components/Pages/About/About'
import { Career } from './Components/Pages/Career/Career'
import { Mobifylife } from './Components/Pages/MobifyLife/Mobifylife'
import {Contact} from './Components/Pages/Contact/Contact'
import {PrivacyPolicy} from './Components/Pages/PrivacyPolicy'
import { MobHeader2 } from './Components/Pages/Header/MobHeader2';
import PageTitle from './Components/utils/PageTitle';
import PageDescription from './Components/utils/PageDescription';
import Blog from './Components/Pages/Blog/Blog';
import BlogDetails from './Components/Pages/Blog/BlogDetails';



function App() {
  
  

  
  return (


    <Router>
      <div className="App">
        <header className="App-header">
          <Header />
          <MobHeader2/>
        </header>
        
        <div className="App-content">
          <Routes>
            <Route path="/" element={ <> <PageTitle title="Mobify" /> <PageDescription description="We specialize in crafting world class games, apps, and web solutions, driven by an unwavering commitment to unparalleled quality. Our dedication extends beyond products to the development of skilled professionals fostering excellence in the industry." />          <Home /></>} />
            <Route path="/games" element={<> <PageTitle title="Games" /> <PageDescription description="We craft most memorable experiences beyond pixels beyond play."/> <Games /></>} />
            <Route path="/apps" element={<> <PageTitle title="APPs" /> <PageDescription description="Apps that makes a difference beyond imaginations beyond limits."/> <Apps /> </>} />
            <Route path="/services" element={<> <PageTitle title="Services" /> <PageDescription description="Mobify thrives on pushing the boundaries of technology and storytelling, crafting captivating experiences that leave a lasting impact."/> <Services /> </>} />
            <Route path="/about" element={<> <PageTitle title="About" /> <PageDescription description="To make lives delightful with the best products."/> <About /> </>} />
            <Route path="/career" element={<> <PageTitle title="Career" /> <PageDescription description="Transforming passions into professions."/> <Career /> </>} />
            <Route path="/lifeatmobify" element= {<> <PageTitle title="Life at Mobify" /> <PageDescription description="Experience the Thrill of Creating Digital Wonders, Discover the moments that make us."/> <Mobifylife /> </>} />
            <Route path="/blog" element= {<> <PageTitle title="Blog" /> <PageDescription description="Blog"/> <Blog/> </>} />
            <Route path="/blog/:id" element={<BlogDetails />} />
            <Route path="/contact" element={<> <PageTitle title="Contact Us" /> <PageDescription description="Open the door,Join the Journey."/> <Contact /> </>} />
            <Route path="/privacypolicy" element={<> <PageTitle title="Privacy Policy" /> <PageDescription description="Mobify Privacy Policy to define our guidelines"/> <PrivacyPolicy /> </>} />

          </Routes>
        </div>

        <footer className='App-footer'>
          <Footer />
        </footer>
      </div>
    </Router>
  );
}

export default App;
