import React, { useState, useEffect, useRef } from "react";
import Slider from "react-slick";
import Card from "react-bootstrap/Card";
import { Container, Row, Col } from "react-bootstrap";
import $ from "jquery"; // Import jQuery

import play from "../../Assets/Website Assets/Games/Games/play.webp";

import ios from "../../Assets/Website Assets/Games/Games/ios.webp";

import DownloadIcon from "../../Assets/Website Assets/Games/Games/DownloadIcon.webp";

const AsNavFor = ({ items }) => {
  console.log(items , "items")
  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  let sliderRef1 = useRef(null);
  let sliderRef2 = useRef(null);
  useEffect(() => {
    setNav1(sliderRef1);
    setNav2(sliderRef2);
  }, []);
  const API_URL = process.env.REACT_APP_API_URL;
  return (
    <div className="slider-container">
      <Container
        className="main-slider mycontainer"
        data-aos="fade-up"
        data-aos-duration="1500"
      >
        <Slider
          asNavFor={nav2}
          ref={(slider) => (sliderRef1 = slider)}
          adaptiveHeight={true} 
        >
          {items?.map((item, index) => (
            <div className="game-card" key={index}>
              <Card>
                <Card.Img variant="top" src={item.image} />
                <Card.Body>
                  <Row className="card-row">
                    <Col xs={3}>
                      <img src={
                   item.icon?.startsWith("/")
                      ? `${API_URL}${item.icon}`
                      : item.icon || "fallback-image.jpg"
                  } alt="icon" />
                    </Col>
                    <Col xs={9}>
                      <h4>{item.title}</h4>
                      <h5>
                        <img
                          src={DownloadIcon}
                          className="DownloadIcon"
                          alt=""
                          loading="eager"
                        />{" "}
                        {item.Downloads}
                      </h5>
                    </Col>
                  </Row>
                  <Row
                    style={{ justifyContent: "center" }}
                    className="Rowbuttons"
                  >
                    <Col xs={5}>
                      <a href={item.buttonAndroidLink}>
                        <img src={play} alt="Play Store" />
                      </a>
                    </Col>

                    {item.hasOwnProperty("buttonAndroidLink2") && (
                      <Col xs={6}>
                        <button href={item.buttonAndroidLink2}>
                          <img src={ios} alt="iOS Store" />
                        </button>
                      </Col>
                    )}
                  </Row>
                </Card.Body>
              </Card>
            </div>
          ))}
        </Slider>
      </Container>
 
      <Slider
        asNavFor={nav1}
        ref={(slider) => (sliderRef2 = slider)}
        slidesToShow={3}
        swipeToSlide={true}
        focusOnSelect={true}
        className="thumbnail-slider"
        data-aos="fade-up"
        data-aos-duration="1500"
      >
        {items?.map((item, index) => (
          <div className="thumbnail" key={index}>
            <Card style={{ width: "28vw" }}>
              <Card.Img variant="top" src={
                   item.icon?.startsWith("/")
                      ? `${API_URL}${item.icon}`
                      : item.icon || "fallback-image.jpg"
                  } alt="icon" loading="eager"  />
              <Card.Body>
                <Card.Title>{item.thumbnailtext}</Card.Title>
              </Card.Body>
            </Card>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default AsNavFor;
