import { Carousel, Card, Row, Col, Button } from "react-bootstrap";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs"; // Import arrow icons

const CustomCarousel = ({ items }) => {
  console.log(items , "services")
  return (
    <div className="custom-carousel-container" data-aos="fade-up">
    <Carousel
      nextIcon={<BsChevronRight className="carousel-control-icon" />}
      prevIcon={<BsChevronLeft className="carousel-control-icon" />}
      indicators={false}
      controls={true}
    >
      {items?.map((service, index) => (
        <Carousel.Item key={index}>
          <Card>
            <Card.Body>
              <Row>
                <Col xs={3}>
                  <Card.Img variant="top" src={service.image} />
                </Col>
                <Col xs={7}>
                  <div>
                    <Card.Title>{service.title}</Card.Title>
                  </div>
                </Col>
              </Row>
              <Card.Text>{service.description}</Card.Text>
              <Row>
                <Col xs={5}>
                  <hr className="card-hr" />
                </Col>
                <Col xs={7}>
                  <Button variant="primary" href="/services">
                    Read More
                  </Button>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Carousel.Item>
      ))}
    </Carousel>
  </div>
  );
};

export default CustomCarousel;
