import React from "react";
import "./Home.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Lottie from "react-lottie";
import { useEffect, useState } from "react";
import GenericCarousel from "./GenericCarousel";
import GameCarousel from "./GameCarousel"; // Assuming the component is in the same directory
import TechCarousel from "./TechCarousel";
import { useMediaQuery } from "react-responsive";
import { Carousel } from "react-bootstrap";
import AOS from "aos";
import "aos/dist/aos.css"; // You can also use <link> for styles

import border from "../../Assets/Website Assets/About/border.webp";

// import lottie from 'lottie-web';

import BoyAnim from "../../Assets/Website Assets/Animations/New/Home page boy and car.json";
import CircleAnim from "../../Assets/Website Assets/Animations/New/LOGO .json";
import ClockAnim from "../../Assets/Website Assets/Animations/New/Home page G.json";

import div1img1 from "../../Assets/Website Assets/Home/div1img1.webp";
import div1img2 from "../../Assets/Website Assets/Home/div1img2.webp";
import div1img3 from "../../Assets/Website Assets/Home/div1img3.webp";

import div2img1 from "../../Assets/Website Assets/Home/Home-div-img1.webp";

import div2img3 from "../../Assets/Website Assets/Home/Home-div-img2.webp";

import div2img2 from "../../Assets/Website Assets/Home/Home-div-img3.webp";

import div2img4 from "../../Assets/Website Assets/Home/Home-div-img4.webp";

import cardimg1 from "../../Assets/Website Assets/Home/Home-cardimg1.webp";
import cardimg2 from "../../Assets/Website Assets/Home/Home-cardimg2.webp";
import cardimg3 from "../../Assets/Website Assets/Home/Home-cardimg3.webp";
import cardimg4 from "../../Assets/Website Assets/Home/Home-cardimg4.webp";
import cardimg5 from "../../Assets/Website Assets/Home/Home-cardimg5.webp";
import cardimg6 from "../../Assets/Website Assets/Home/Home-cardimg6.webp";

import Mcardimg1 from "../../Assets/Website Assets/Home/web development.webp";
import Mcardimg2 from "../../Assets/Website Assets/Home/app development.webp";
import Mcardimg3 from "../../Assets/Website Assets/Home/vfx.webp";
import Mcardimg4 from "../../Assets/Website Assets/Home/UI UX.webp";
import Mcardimg5 from "../../Assets/Website Assets/Home/Games development.webp";
import Mcardimg6 from "../../Assets/Website Assets/Home/Games and Publishing.webp";

import div3img1 from "../../Assets/Website Assets/Home/div3-img1.webp";
import div3img2 from "../../Assets/Website Assets/Home/div3-img2.webp";

// import div3img1 from '../../Assets/Website Assets/Home/'
// import div3img1 from '../../Assets/Website Assets/Home/'

import play from "../../Assets/Website Assets/Home/Play.webp";
import ios from "../../Assets/Website Assets/Home/ios1.webp";

import div4img1 from "../../Assets/Website Assets/Home/Group 34.webp";

import Game1 from "../../Assets/Website Assets/Home/Game1.webp";
import Game2 from "../../Assets/Website Assets/Home/Game2.webp";
import Game3 from "../../Assets/Website Assets/Home/Game3.webp";

// Div 6 Imports

import Award1 from "../../Assets/Website Assets/Home/Group 125.webp";
import Award2 from "../../Assets/Website Assets/Home/Group 403 (1).webp";
import Award3 from "../../Assets/Website Assets/Home/Group 126.webp";

import python from "../../Assets/Website Assets/Home/python.webp";
import kotlin from "../../Assets/Website Assets/Home/kotlin.webp";
import andriod from "../../Assets/Website Assets/Home/andriod.webp";
import xcode from "../../Assets/Website Assets/Home/xcode.webp";
import adobe from "../../Assets/Website Assets/Home/adobe.webp";
import unity from "../../Assets/Website Assets/Home/unity.webp";

import pythonC from "../../Assets/Website Assets/Home/pythonC.webp";
import kotlinC from "../../Assets/Website Assets/Home/kotlinC.webp";
import andriodC from "../../Assets/Website Assets/Home/andriodC.webp";
import xcodeC from "../../Assets/Website Assets/Home/xcodeC.webp";
import adobeC from "../../Assets/Website Assets/Home/AdobeC.webp";
import unityC from "../../Assets/Website Assets/Home/unityC.webp";

import div6static1 from "../../Assets/Website Assets/Home/div6static1.png";

import div6static2 from "../../Assets/Website Assets/Home/div6static2.png";

import backimgdiv7 from "../../Assets/Website Assets/Home/backimg-div7.webp";

import HomeImpactBanner from "../../Assets/Website Assets/Home/HomeImpactBanner.webp";

// import impact1 from '../../Assets/Website Assets/Home/impact1.png'
// import impact2 from '../../Assets/Website Assets/Home/impact2.png'
// import impact3 from '../../Assets/Website Assets/Home/impact3.png'
// import impact4 from '../../Assets/Website Assets/Home/impact4.png'

import GamePlayData from "../../Assets/Website Assets/Animations/New/Main Home Animation .mp4";

import MCircle1 from "../../Assets/Website Assets/Home/Home-Mobile1.webp";
import MCircle2 from "../../Assets/Website Assets/Home/Home-Mobile2.webp";
import MCircle3 from "../../Assets/Website Assets/Home/HomeMid.webp";
import AwardsCarousel from "./AwardsCarousel";
import axios from "axios";

export const Home = () => {
  // In your React component
  useEffect(() => {
    document.title = "Mobify"; // Set your desired page title here
    document
      .querySelector('meta[name="title"]')
      .setAttribute("content", "Home | Mobify");
    document
      .querySelector('meta[name="description"]')
      .setAttribute(
        "content",
        "We specialize in crafting world class games, apps, and web solutions, driven by an unwavering commitment to unparalleled quality. Our dedication extends beyond products to the development of skilled professionals fostering excellence in the industry."
      );
    document
      .querySelector('meta[name="keywords"]')
      .setAttribute(
        "content",
        "mobify, mobify islamabad, game development, app development"
      );
  }, []);

  const [Mappedjobs, setMappedJobs] = useState([]);
  const [contentData, setContentData] = useState({});
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [configData, setConfigData] = useState({});
  const [portFolio, setPortFolio] = useState({});
  const [projects, setProjects] = useState([]);
  const [projectsMobile, setProjectsMobile] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  console.log(configData, "configData");
  const API_URL = process.env.REACT_APP_API_URL;
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch the first API
        const response = await axios.get(`${API_URL}/api/content/`);
        const response2 = await axios.get(
          `${API_URL}/api/config/values-configs/`
        );
        const portfolio = await axios.get(
          `${API_URL}/api/dashboard/portfolios?device=desktop`
        );
        const portfoliomobile = await axios.get(
          `${API_URL}/api/dashboard/portfolios?device=mobile`
        );

        // Process the first API data
        var section_lists = [];
        const mappedData = response.data.results.reduce((acc, item) => {
          if (!section_lists[item.section_name]) {
            section_lists[item.section_name] = [];
          }

          section_lists[item.section_name].push(item);
        }, {});
        setContentData(section_lists); // Store mapped data in state

        // Process the second API data (key-value mapping)
        const configMapped = response2.data.results.reduce((acc, item) => {
          acc[item.key] = item.value; // Map key to value
          return acc;
        }, {});

        setConfigData(configMapped); // Store mapped key-value pairs
        var Portfolio_section_lists = [];

        // const PortFolioLists = portfolio.data.results?.map((item, idx) => {
        //   var single_section_portfolio = [];
        //   var item_port = item.section;
        //   portfolio.data.results?.map((itm, idx) => {
        //     if (itm.section == item_port) {
        //       single_section_portfolio.push(itm);
        //     }
        //   });
        //   Portfolio_section_lists[item_port] = single_section_portfolio;
        // }, {});
        // setPortFolio(Portfolio_section_lists); // Store mapped section dat
        // console.log(Portfolio_section_lists, "PortFolioLists");

        const apps = portfolio.data.results.filter(
          (project) => project.section === "home"
        );
        const portfolioMobile = portfoliomobile.data.results.filter(
          (project) => project.section === "home"
        );

        setProjects(apps);
        setProjectsMobile(portfolioMobile);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
    // Add window resize listener
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const services = [
    {
      title: "Web Development",
      image: cardimg1,
      description:
        contentData["Web Development"]?.[0]?.description ||
        "Loading description for home page...",
    },
    {
      title: "Apps Development",
      image: cardimg2,
      description:
        contentData["Apps Development"]?.[0]?.description ||
        "Loading description for home page...",
    },
    {
      title: "Games Development",
      image: cardimg3,
      description:
        contentData["Games Development"]?.[0]?.description ||
        "Loading description for home page...",
    },
    {
      title: "UI/UX Designing",
      image: cardimg4,
      description:
        contentData["UI/UX Designing"]?.[0]?.description ||
        "Loading description for home page...",
    },
    {
      title: "Games and Apps Publishing",
      image: cardimg5,
      description:
        contentData["Games and Apps Publishing"]?.[0]?.description ||
        "Loading description for home page...",
    },
    {
      title: "VFX",
      image: cardimg6,
      description:
        contentData["VFX"]?.[0]?.description ||
        "Loading description for home page...",
    },
  ];
  const servicesMobile = [
    {
      title: "Web Development",
      image: Mcardimg1,
      description:
        contentData["Web Development"]?.[0]?.description ||
        "Loading description for home page...",
    },
    {
      title: "Apps Development",
      image: Mcardimg2,
      description:
        contentData["Apps Development"]?.[0]?.description ||
        "Loading description for home page...",
    },
    {
      title: "Games Development",
      image: Mcardimg3,
      description:
        contentData["Games Development"]?.[0]?.description ||
        "Loading description for home page...",
    },
    {
      title: "UI/UX Designing",
      image: Mcardimg4,
      description:
        contentData["UI/UX Designing"]?.[0]?.description ||
        "Loading description for home page...",
    },
    {
      title: "Games and Apps Publishing",
      image: Mcardimg5,
      description:
        contentData["Games and Apps Publishing"]?.[0]?.description ||
        "Loading description for home page...",
    },
    {
      title: "VFX",
      image: Mcardimg6,
      description:
        contentData["VFX"]?.[0]?.description ||
        "Loading description for home page...",
    },
  ];
  //
  const carouselItems = [
    {
      image: Mcardimg1,
      title: "Web Development",
      text: "Concept, Code, Click: Developing Seamless Web Experiences",
    },
    {
      image: Mcardimg2,
      title: "Apps Development",
      text: "Touch, Tap, Transform: Crafting Mobile Apps That Resonate",
    },
    {
      image: Mcardimg3,
      title: "VFX",
      text: "Art of Visual Magic: Transformative VFX Services for Enchanting Visuals",
    },
    {
      image: Mcardimg4,
      title: "UI/UX Designing",
      text: "Aesthetic Ingenuity (UI/UX)",
    },
    {
      image: Mcardimg5,
      title: "Games Development",
      text: "Quests and Codes: Engineering Your Gaming dreams into Reality",
    },
    {
      image: Mcardimg6,
      title: "Games and Apps Publishing",
      text: "Reach The Stars: Publish Your Game/App with Us",
    },
  ];
  useEffect(() => {
    const mappedcontent = Object.values(contentData)?.map((section) => ({
      page: section.page || "",
      description: section.description || "",
    }));

    setMappedJobs(mappedcontent);
    console.log("mappedcontent:", mappedcontent);
  }, [contentData]);
  console.log(contentData, "contentData");

  // Define your array of items with image, h3, and h4 values
  const Gameitems = [
    {
      url1: "https://play.google.com/store/apps/details?id=com.volcano.city.airplane.pilotflight",
      url2: "https://apps.apple.com/ie/app/airplane-pilot-flight-game/id1478861703",
      image: Game1,
      title: "100M+",
      subtitle: "Downloads",
    },
    {
      url1: "https://play.google.com/store/apps/details?id=com.fun.games.commando.black.shadow",
      url2: "https://apps.apple.com/ie/app/fps-gun-shooting-games-online/id1528286345",
      image: Game2,
      title: "100M+",
      subtitle: "Downloads",
    },
    {
      url1: "https://play.google.com/store/apps/details?id=com.knights.bikesstunt.motomaster",
      url2: "https://apps.apple.com/ie/app/bike-stunt-3d-motorbike-games/id1474924445",
      image: Game3,
      title: "100M+",
      subtitle: "Downloads",
    },
    {
      url1: "https://play.google.com/store/apps/details?id=com.volcano.city.airplane.pilotflight",
      url2: "https://apps.apple.com/ie/app/airplane-pilot-flight-game/id1478861703",
      image: Game1,
      title: "100M+",
      subtitle: "Downloads",
    },
    {
      url1: "https://play.google.com/store/apps/details?id=com.fun.games.commando.black.shadow",
      url2: "https://apps.apple.com/ie/app/fps-gun-shooting-games-online/id1528286345",
      image: Game2,
      title: "100M+",
      subtitle: "Downloads",
    },
    {
      url1: "https://play.google.com/store/apps/details?id=com.knights.bikesstunt.motomaster",
      url2: "https://apps.apple.com/ie/app/bike-stunt-3d-motorbike-games/id1474924445",
      image: Game3,
      title: "100M+",
      subtitle: "Downloads",
    },

    // Add more items as needed
  ];

  const awards = [
    {
      id: 1,
      image: Award1,
      title: "Huawei Award",
      year: "2021",
    },

    {
      id: 2,
      image: Award2,
      title: "Pasha ICT 2022",
      year: "Merit Award",
    },

    {
      id: 3,
      image: Award3,
      title: "Pasha ICT 2023",
      year: "Merit Award",
    },

    {
      id: 4,
      image: Award3,
      title: "Pasha ICT 2023",
      year: "Game of the year",
    },
  ];

  const techs = [
    {
      id: 1,
      image: python,
    },
    {
      id: 2,
      image: kotlin,
    },
    {
      id: 3,
      image: adobe,
    },

    {
      id: 4,
      image: unity,
    },

    {
      id: 5,
      image: andriod,
    },
    {
      id: 6,
      image: xcode,
    },
  ];

  const [videoEnded, setVideoEnded] = useState(false);

  // Event handler for when the video ends
  const handleVideoEnd = () => {
    setVideoEnded(true);
  };

  // Add event listener to initialize AOS when the component mounts
  useEffect(() => {
    AOS.init({
      once: true, // Ensure animation only occurs once
      offset: 270, // Adjust offset as needed to trigger animation earlier/later
      // disable: 'mobile', // Disable on mobile devices if needed
      startEvent: "scroll", // Trigger animation on scroll event
      useClassNames: true, // Apply class names to elements
      initClassName: "aos-init", // Class applied during initialization
      animatedClassName: "aos-animate", // Class applied on animation
      // duration: 1000, // Duration of animations in milliseconds
      easing: "ease", // Easing function for animations
    });
  }, []);

  // Flipping Card Animation

  // Flipping Card Animation

  const [div7InView, setDiv7InView] = useState(false);

  useEffect(() => {
    const div7Element = document.getElementById("div7");

    if (div7Element) {
      const options = {
        root: null, // Viewport as the root
        rootMargin: "0px", // No margin
        threshold: 0.8, // Trigger when at least 80% of the element is visible
      };

      const observer = new IntersectionObserver((entries) => {
        const entry = entries[0];
        if (entry.isIntersecting) {
          console.log("div7Element is in view");

          // Animate cards with configData values
          animateCard(
            "products-number",
            parseInt(configData["Products"] || 0),
            3000
          );
          animateCard(
            "downloads-number",
            parseInt(configData["Downloads"] || 0),
            3000
          );
          animateCard("team-number", parseInt(configData["Team"] || 0), 3000);

          observer.disconnect(); // Stop observing after triggering
        }
      }, options);

      observer.observe(div7Element);

      return () => {
        observer.disconnect();
      };
    } else {
      console.error("div7Element not found");
    }
  }, [configData]); // Re-run if configData updates

  // Function to animate the card flipping and incrementing

  function animateCard(cardId, finalNumber, duration) {
    const element = document.getElementById(cardId);
    if (!element) {
      console.error(`Element with ID ${cardId} not found`);
      return;
    }

    console.log(`Animating ${cardId} to ${finalNumber} over ${duration}ms`);

    let currentNumber = 0;
    const intervalTime = 30; // Update every 30ms
    const totalSteps = duration / intervalTime; // Total number of steps
    const increment = finalNumber / totalSteps; // Increment per step

    const interval = setInterval(() => {
      currentNumber += increment;

      if (currentNumber >= finalNumber) {
        currentNumber = finalNumber; // Cap to final value
        clearInterval(interval);
      }

      updateNumber(cardId, Math.floor(currentNumber));
    }, intervalTime);
  }

  function updateNumber(cardId, number) {
    const element = document.getElementById(cardId);
    if (element) {
      element.innerText = number; // Update the text in the card
      console.log(`Updated ${cardId} to ${number}`);
    } else {
      console.error(`Element with ID ${cardId} not found`);
    }
  }

  const defaultOptions = {
    animationData: BoyAnim,
    loop: true,
    autoplay: true,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const Anim1 = {
    loop: true,
    autoplay: true,
    animationData: CircleAnim, // Pass the imported animation data
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const Anim2 = {
    loop: true,
    autoplay: true,
    animationData: ClockAnim, // Pass the imported animation data
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const baseURL = "https://fd21-115-186-148-210.ngrok-free.app";
  // Function to update the number on the card dynamically
  function updateNumber(cardId, number) {
    const element = document.getElementById(cardId);
    if (element) {
      element.textContent = number;
    }
  }

  const isDesktopOrLaptop = useMediaQuery({ minDeviceWidth: 768 });

  return (
    <div className="home">
      <div className={`div1 ${videoEnded ? "Background-apply" : "min-h"}`}>
        <div className="mycontainer">
          <img
            src={div1img1}
            className={` ${videoEnded ? "img1" : "dn Lblock"}`}
            alt=""
          />
          <img
            src={div1img2}
            className={`  ${videoEnded ? "img2" : "dn Lblock"}`}
            alt=""
          />
          <img
            src={div1img3}
            className={` ${videoEnded ? "img3" : "dn Lblock"}`}
            alt=""
          />

          <img src={MCircle1} className="H-Mimg1 MCircle" alt="" />
          <img src={MCircle2} className="H-Mimg2 MCircle" alt="" />
          <img src={MCircle3} className="H-Mimg3 MCircle" alt="" />
        </div>

        <Container>
          <Row>
            {isDesktopOrLaptop && (
              <>
                {!videoEnded ? (
                  <Col xs={12}>
                    <video
                      controls={false} // Hide the controls
                      onEnded={handleVideoEnd}
                      width="100%"
                      autoPlay={true} // Autoplay the video
                      muted
                    >
                      <source src={GamePlayData} type="video/mp4" />
                    </video>
                  </Col>
                ) : (
                  <>
                    <Col
                      className="innovation col"
                      xs={12}
                      md={6}
                      lg={5}
                      xl={6}
                    >
                      {/* <h1>
                        Experience <br /> digital innovation <br /> in Every
                        Byte
                      </h1> */}
                      {contentData["Hero"] &&
                        contentData["Hero"]
                          .filter((item) => item.page === "home")
                          ?.map((item, idx) => (
                            <h1 key={idx}>{item.description}</h1>
                          ))}
                    </Col>
                    <Col className=" col LAnim" xs={12} md={6}>
                      <Lottie
                        className="lottie-desktop"
                        options={defaultOptions}
                        style={{ height: "auto", width: "49vw" }}
                      />
                    </Col>
                  </>
                )}
              </>
            )}

            <Col
              className="innovation col Mblock"
              xs={12}
              md={{ span: 5, offset: 1 }}
            >
              {contentData["Hero"] &&
                contentData["Hero"]
                  .filter((item) => item.page === "home")
                  ?.map((item, idx) => <h1 key={idx}>{item.description}</h1>)}
              {/* <h1>Experience digital innovation in Every Byte</h1> */}
            </Col>

            <Col className="MAnim" xs={12}>
              <Lottie
                options={defaultOptions}
                style={{ width: "100%", height: "auto" }}
              />
            </Col>
          </Row>
        </Container>
      </div>
      {/* <div className="div2" style={{backgroundImage: `url(${div2back})`}}> */}
      <div className="div2" data-aos="fade-up" data-aos-duration="1000">
        <img
          src={div2img1}
          className="img1 Lblock"
          data-aos="fade-up"
          data-aos-duration="1000"
          alt=""
        />
        <img
          src={div2img2}
          className="img2 Lblock"
          data-aos="fade-up"
          data-aos-duration="1000"
          alt=""
        />
        <img
          src={div2img3}
          className="img3 Lblock"
          data-aos="fade-up"
          data-aos-duration="1000"
          alt=""
        />
        <img
          src={div2img4}
          className="img4 Lblock"
          data-aos="fade-up"
          data-aos-duration="1000"
          alt=""
        />

        <Container>
          <Row className="Lblock">
            <Col xs={12} data-aos="fade-up" data-aos-duration="1000">
              {" "}
              {/* Use xs={12} to span the full width on extra small screens */}
              <h2>Behind Our Name</h2>
            </Col>
            <Col
              xs={12}
              md={5}
              className="col"
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-delay="200"
            >
              {/* <img src={HomeDiv2Banner} alt="" /> */}
              <Lottie
                className="circleAnim"
                options={Anim1}
                style={{ width: "100%", height: "auto" }}
              />
            </Col>
            <Col
              xs={12}
              md={6}
              className="col"
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-delay="200"
            >
              <h3>
                {contentData?.["Behind Our Name"]?.[0]?.description ||
                  "Loading description for home page..."}
              </h3>

              {/* Display the description for the "home" page */}
              {/* {contentData["Hero"] &&
                contentData["Hero"].map((itemss, idx) => <h3>{itemss.description}</h3>)} */}
            </Col>
          </Row>

          <Row className="Mblock">
            <Col xs={12}>
              {" "}
              {/* Use xs={12} to span the full width on extra small screens */}
              <h2
                style={{ margin: "0 0 -2rem 0" }}
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                Behind Our Name
              </h2>
            </Col>
            <Col
              xs={12}
              md={7}
              className="col"
              style={{ marginTop: "2rem" }}
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              {/* <h3>
                For the past decade, we’ve been in the business of creating
                magic with a dedicated team of over 148 talented individuals,
                we’ve developed more than 52 unique projects that have garnered
                a staggering 2.3 billion downloads. Yes, you read that right
                that’s billion with a ‘B’! So whether you are a startup with a
                bold idea or an established enterprise seeking to redefine the
                future, Let us collaborate
              </h3>{" "} */}
              <h3>
                {/* Display the description for the "home" page */}
                {contentData?.["Behind Our Name"]?.[0]?.description ||
                  "Loading description for home page..."}
              </h3>
            </Col>
            <Col
              xs={12}
              md={5}
              className="col"
              style={{ marginTop: "-3rem" }}
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              {/* <img src={HomeDiv2Banner} alt="" /> */}
              <Lottie
                className="circleAnim"
                options={Anim1}
                style={{ width: "100%", height: "auto" }}
              />
            </Col>
          </Row>
        </Container>
      </div>

      <div className="div3 glimpse">
        <img
          src={div3img1}
          className="img1 Lblock"
          data-aos="fade-up"
          data-aos-duration="1000"
          alt=""
        />
        <img
          src={div3img2}
          className="img2 Lblock"
          data-aos="fade-up"
          data-aos-duration="1000"
          alt=""
        />

        <Container>
          <Row>
            <Col className="Mblock">
              <Col md={12}>
                <h2 data-aos="fade-up" data-aos-duration="1000">
                  Glimpse of <br /> what we offer
                </h2>
              </Col>
              <GenericCarousel
                data-aos="fade-up"
                data-aos-duration="1000"
                items={servicesMobile}
              />
            </Col>
          </Row>

          <Row className="Lblock">
            <h2
              style={{ padding: "0 0 4rem 0" }}
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              Glimpse of what we offer
            </h2>
            {services.map((service, index) => (
              <Col
                key={index}
                sm={12}
                md={4}
                className="col"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                <Card>
                  <Card.Body>
                    <Row>
                      <Col lg={3} md={4}>
                        <Card.Img variant="top" src={service.image} />
                      </Col>
                      <Col lg={8} md={8}>
                        <div>
                          <Card.Title>{service.title}</Card.Title>
                        </div>
                      </Col>
                    </Row>
                    <Card.Text>{service.description}</Card.Text>
                    <hr className="card-hr" />

                    <a href="/services" rel="noreferrer">
                      {" "}
                      <Button variant="primary">Read More</Button>
                    </a>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        </Container>
      </div>

      <div className="div4 Lblock" data-aos="fade-up" data-aos-duration="1000">
        <Container>
          <Row>
            <Col>
              <h2>Crafted for Gamers, By Gamers</h2>
            </Col>
            <img className="Lblock" src={div4img1} alt="" />
          </Row>
        </Container>
      </div>

      <div className="div5">
        <Container className="Lblock">
          <Row className="row-margin-top justify-content-center">
            <Carousel
              data-aos="fade-up"
              data-aos-duration="1000"
              indicators={false}
            >
              {projects
                .reduce((result, item, index) => {
                  const slideIndex = Math.floor(index / 3);
                  if (!result[slideIndex]) result[slideIndex] = [];
                  result[slideIndex].push(item);
                  return result;
                }, [])
                ?.map((slide, slideIdx) => (
                  <Carousel.Item key={slideIdx}>
                    <Row className="row-margin-top justify-content-center">
                      {slide?.map((project, idx) => (
                        <Col key={idx} s={12} md={3}>
                          <Card>
                            <div className="image-container">
                              <Card.Img
                                variant="top"
                                id="opImg"
                                src={
                                  project.image?.startsWith("/")
                                    ? `${API_URL}${project.image}`
                                    : project.image || "fallback-image.jpg"
                                }
                              />
                              <div className="overlay"></div>
                              <div className="buttons">
                                {project.project_android_url && (
                                  <a
                                    href={project.project_android_url}
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    <img src={play} alt="Play Store" />
                                  </a>
                                )}
                                {project.project_ios_url && (
                                  <a
                                    href={project.project_ios_url}
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    <img src={ios} alt="App Store" />
                                  </a>
                                )}
                              </div>
                            </div>
                            <Card.Body>
                              <Card.Text>
                                <h3>{project.download || "N/A"}</h3>
                                {/* <h4>{project.project_name}</h4> */}
                                <h4 className="downloads">Downloads</h4>
                              </Card.Text>
                            </Card.Body>
                          </Card>
                        </Col>
                      ))}
                    </Row>
                  </Carousel.Item>
                ))}
            </Carousel>
          </Row>
        </Container>

        <div className="Mblock" data-aos="fade-up" data-aos-duration="1000">
          <Col className="Mblock">
            <h2>
              Crafted for Gamers,
              <br /> By Gamers
            </h2>
          </Col>
          <GameCarousel items={projectsMobile} />
        </div>
      </div>

      <div className="div6">
        <img src={div6static1} className="div6static1 Lblock" alt="" />
        <img src={div6static2} className="div6static2 Lblock" alt="" />

        <Container className="Lblock">
          <Row
            className="justify-content-center awards"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            <Col md={12}>
              <h2>Our Hall of Fame</h2>
            </Col>

            {contentData["Our Hall of Fame"] &&
              contentData["Our Hall of Fame"]?.map((award, index) => (
                <Col sm={12} md={2} key={award.id || index}>
                  <Card>
                    <Card.Img
                      variant="top"
                      src={
                        award.image.startsWith("http")
                          ? award.image
                          : `${API_URL}${award.image}`
                      }
                      alt={award.description}
                    />
                    <Card.Body>
                      <Card.Text>
                        <h4>{award.description}</h4>
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
              ))}
          </Row>
        </Container>

        <div
          className="Mblock"
          style={{ paddingBottom: "9rem" }}
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          <h2>Our Hall of Fame</h2>

          <AwardsCarousel
            awards={
              contentData["Our Hall of Fame"]?.map((award) => ({
                id: award.id,
                image: award.image.startsWith("http")
                  ? award.image
                  : `${API_URL}${award.image}`,
                description: award.description,
              })) || []
            }
          />
        </div>

        <Container fluid>
          <Row className="Mtech-row">
            <Col md={12} data-aos="fade-up" data-aos-duration="1000">
              <h2 style={{ paddingTop: "2rem" }}>Technologies We Work On</h2>
            </Col>

            <Row
              className="justify-content-center div6-tech-row Lblock"
              data-aos-duration="1000"
            >
              {contentData["Technologies We Work On"] &&
                contentData["Technologies We Work On"]?.map((tech, idx) => (
                  <Col sm={6} md={2} lg={2} key={idx} className="row1Col">
                    <img

                      src={`${API_URL}${tech.image}`}
                      className="tech-image"
                      style={{ width: "100%" }}
                      alt={`Technology ${idx}`}
                    />
                  </Col>
                ))}
            </Row>

            {/* <div className="Mblock" data-aos="fade-up" data-aos-duration="500">
             <TechCarousel items={techs} />
            </div>  */}
            <div className="Mblock" data-aos="fade-up" data-aos-duration="500">
              {contentData["Technologies We Work On"]?.length > 0 && (
                <TechCarousel
                  items={contentData["Technologies We Work On"].map((tech) => ({
                    image: `${API_URL}${tech.image}`,
                    name: tech.name,
                  }))}
                />
              )}
            </div>
          </Row>
        </Container>
      </div>

      <div
        id="div7"
        className="div7 impact"
        style={{ backgroundImage: `url(${backimgdiv7})` }}
      >
        <Container>
          <Row>
            <Col
              xs={12}
              data-aos="fade-up"
              style={{
                animationTimingFunction: "cubic-bezier(0.25, 0.1, 0.25, 1.5)", // Adjust the cubic bezier values as needed
              }}
            >
              <h2>Impact & Reach</h2>
            </Col>
          </Row>
          <Row
            className="MReverse"
            data-aos="fade-up"
            style={{
              animationTimingFunction: "cubic-bezier(0.25, 0.1, 0.25, 1.5)", // Adjust the cubic bezier values as needed
            }}
          >
            <Col className="Mview" md={5}>
              <Row className="spec-row">
                <Col xs={4} className="spec-col card1">
                  <img src={border} className="bor1" alt="" />
                  <Card id="products-card">
                    <Card.Body>
                      <Card.Text>
                        <h3>
                          <span id="products-number">
                            {configData["Products"] || 0}
                          </span>
                          +
                        </h3>
                        <h4>Products</h4>
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>

                <Col xs={4} className="spec-col">
                  <img src={border} className="bor1" alt="" />
                  <Card id="downloads-card">
                    <Card.Body>
                      <Card.Text>
                        <h3>
                          <span id="downloads-number">
                            {configData["Downloads"] || 0}
                          </span>
                          B
                        </h3>
                        <h4>Downloads</h4>
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>

                <Col xs={4} className="spec-col card2">
                  <Card id="team-card">
                    <Card.Body>
                      <Card.Text>
                        <h3>
                          <span id="team-number">
                            {configData["Team"] || 0}
                          </span>
                          +
                        </h3>
                        <h4>Team</h4>
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </Col>
            <Col className="ImpactCol" md={6}>
              <img src={HomeImpactBanner} className="ImpactBanner" alt="" />
              {/* <img className='alarm' src={div7img} alt="" /> */}
              <div className="impactAnimMargin">
                <Lottie className="clockAnim" options={Anim2} />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};
