import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { Container, Typography } from "@mui/material";

const BlogDetails = () => {
  const { id } = useParams(); // Get blog ID from URL
  const [blog, setBlog] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [headings, setHeadings] = useState([]);
  const API_URL = process.env.REACT_APP_API_URL;
  useEffect(() => {
    const fetchBlogDetails = async () => {
      try {
        setLoading(true);
        const response = await axios.get(
          `${API_URL}/api/mobify/blogs/${id}/`
        );
        setBlog(response.data);
        setLoading(false);

        const parser = new DOMParser();
        const htmlDoc = parser.parseFromString(
          response.data.description,
          "text/html"
        );

        // Extract all headings (h1 to h6)
        const extractedHeadings = Array.from(
          htmlDoc.querySelectorAll("h1, h2, h3, h4, h5, h6")
        ).map((heading, index) => ({
          text: heading.textContent,
          id: `heading-${index}`,
          level: heading.tagName.toLowerCase(), // Store the heading level (h1, h2, etc.)
        }));

        // Add IDs to the headings in the HTML
        extractedHeadings.forEach(({ id }, index) => {
          htmlDoc.querySelectorAll("h1, h2, h3, h4, h5, h6")[index].id = id;
        });

        // Save updated HTML back
        response.data.description = htmlDoc.body.innerHTML;

        setHeadings(extractedHeadings);
        response.data.description = htmlDoc.body.innerHTML;
      } catch (err) {
        setError("Failed to fetch blog details");
        setLoading(false);
        console.error("Error fetching blog details:", err);
      }
    };

    fetchBlogDetails();
  }, [id]);

  const scrollToSection = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  if (loading) {
    return (
      <Container className="py-5 text-center">
        <Typography variant="h6">Loading...</Typography>
      </Container>
    );
  }

  if (error) {
    return (
      <Container className="py-5 text-center">
        <Typography variant="h6" color="error">
          {error}
        </Typography>
      </Container>
    );
  }

  return (
    <Container className="py-4 d-flex">
      <div style={{ flex: 3 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          {blog.title}
        </Typography>
        <img
          src="https://i.imghippo.com/files/PGQm1184zmM.png"
          alt="Blog"
          style={{ width: "100%", maxHeight: "500px", objectFit: "cover" }}
        />
        <Typography
          variant="body1"
          className="mt-4"
          dangerouslySetInnerHTML={{ __html: blog.description }}
        />
        <Typography variant="subtitle2" color="text.secondary" className="mt-2">
          Published on:{" "}
          {new Date(blog.created_at).toLocaleString("en-US", {
            year: "numeric",
            month: "long",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
            hour12: true,
          })}
        </Typography>
      </div>
      <aside style={{ flex: 1, marginLeft: "20px" }}>
        <Typography variant="h6" gutterBottom>
          Table of Content
        </Typography>
        <ul style={{ listStyleType: "none", padding: 0 }}>
          {headings.map((heading) => (
            <li
              key={heading.id}
              style={{
                marginBottom: "10px",
                // paddingLeft: `${(parseInt(heading.level[1]) - 1) * 15}px`, // Indent based on heading level
              }}
            >
              <div className="d-flex gap-2">
           <div>

              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.875 19.5C11.5765 19.5 11.3264 19.3989 11.1249 19.1966C10.9226 18.995 10.8214 18.7449 10.8214 18.4464V13.1786H5.55357C5.25506 13.1786 5.00466 13.0774 4.80238 12.8751C4.60079 12.6736 4.5 12.4235 4.5 12.125C4.5 11.8265 4.60079 11.5761 4.80238 11.3738C5.00466 11.1722 5.25506 11.0714 5.55357 11.0714H10.8214V5.80357C10.8214 5.50506 10.9226 5.25466 11.1249 5.05238C11.3264 4.85079 11.5765 4.75 11.875 4.75C12.1735 4.75 12.4239 4.85079 12.6262 5.05238C12.8278 5.25466 12.9286 5.50506 12.9286 5.80357V11.0714H18.1964C18.4949 11.0714 18.745 11.1722 18.9466 11.3738C19.1489 11.5761 19.25 11.8265 19.25 12.125C19.25 12.4235 19.1489 12.6736 18.9466 12.8751C18.745 13.0774 18.4949 13.1786 18.1964 13.1786H12.9286V18.4464C12.9286 18.7449 12.8278 18.995 12.6262 19.1966C12.4239 19.3989 12.1735 19.5 11.875 19.5Z"
                  fill="#F69E1F"
                />
              </svg>
           </div>

              <button
                onClick={() => scrollToSection(heading.id)}
                style={{
                  background: "none",
                  border: "none",
                  color: "#63676B",
                  cursor: "pointer",
                  textAlign: "left",
                  fontSize: "14px",
                }}
              >
                {heading.text}
              </button>
              </div>
            </li>
          ))}
        </ul>
      </aside>
    </Container>
  );
};

export default BlogDetails;
