import React, { useEffect, useState } from "react";
import "./Blog.css";
import sphere1 from "../../Assets/Website Assets/About/Sphere1.webp";
import sphere2 from "../../Assets/Website Assets/About/Sphere2.webp";
import sphere3 from "../../Assets/Website Assets/About/Sphere3.webp";
import sphere4 from "../../Assets/Website Assets/About/Sphere4.webp";
import ContactM1 from "../../Assets/Website Assets/Contact Us/ContactM1.webp";
import ContactM2 from "../../Assets/Website Assets/Contact Us/ContactM2.webp";
import { Card, CardContent, CardMedia, Typography } from "@mui/material";
import { Container, Row, Col } from "react-bootstrap";
// import { format } from 'date-fns';
import axios from "axios";
import { useNavigate } from "react-router-dom";

const Blog = () => {
  const navigate = useNavigate();
  const [activeCategory, setActiveCategory] = useState("All");
  const [blogData, setBlogData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [allPosts, setAllPosts] = useState([]);

  // Define categories exactly as they appear in the backend response
  const categories = ["All", "Games", "Business", "GameDevelopment", "Tech"];

  // Display names for categories
  const categoryDisplayNames = {
    Games: "Gaming",
    Business: "Business",
    GameDevelopment: "Game Development",
    Tech: "Tech",
  };
  const API_URL = process.env.REACT_APP_API_URL;
  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        setLoading(true);
        const response = await axios.get(
          `${API_URL}/api/mobify/blogs/?category=true`
        );

        // Store the raw response data
        setBlogData(response.data.results);

        // Create array of all posts for "All" category
        const combinedPosts = Object.values(response.data.results).reduce(
          (acc, curr) => [...acc, ...(Array.isArray(curr) ? curr : [])],
          []
        );

        setAllPosts(combinedPosts);
        setLoading(false);
      } catch (err) {
        setError("Failed to fetch blog posts");
        setLoading(false);
        console.error("Error fetching blogs:", err);
      }
    };

    fetchBlogs();
  }, []);

  const getFilteredPosts = () => {
    if (activeCategory === "All") {
      return allPosts;
    }

    return blogData?.[activeCategory] || [];
  };

  const handleReadMore = (blogId) => {
    navigate(`/blog/${blogId}`);
  };

  if (loading) {
    return (
      <Container className="py-5 text-center">
        <Typography variant="h6">Loading...</Typography>
      </Container>
    );
  }

  if (error) {
    return (
      <Container className="py-5 text-center">
        <Typography variant="h6" color="error">
          {error}
        </Typography>
      </Container>
    );
  }

  const filteredPosts = getFilteredPosts();

  return (
    <div className="blog">
      <div className="div1">
        <div className="mycontainer">
          <img src={sphere1} className="sphere1 Lblock" alt="" />
          <img src={sphere2} className="sphere2 Lblock" alt="" />
          <img src={sphere3} className="sphere3 Lblock" alt="" />
          <img src={sphere4} className="sphere4 Lblock" alt="" />
          <img src={ContactM1} className="sphere1 Mblock" alt="" />
          <img src={ContactM2} className="sphere2 Mblock" alt="" />
        </div>
        <div className="hero">
          <div className="hero-content-heading">
            <h1>Latest News</h1>
            <p className="blog-para">
              Navigate our blog wrapped with the latest technology knowledge,
              trends, innovations, and insights.
            </p>
          </div>
          <img src="https://i.imghippo.com/files/mOG5323RM.png" alt="Blog" />
        </div>
      </div>

      <Container className="py-4">
        <div className="d-flex gap-2 mb-4 flex-wrap justify-content-center align-items-center">
          {categories.map((category) => (
            <button
              key={category}
              onClick={() => setActiveCategory(category)}
              className={`px-4 py-2 rounded border ${
                activeCategory === category ? "#63676B" : "text-dark"
              }`}
              style={{
                border: "1px solid #dee2e6",
                borderRadius: "8px", 
                transition: "all 0.3s ease",
                cursor: "pointer",
                background:
                  activeCategory === category
                    ? "linear-gradient(to right, #FFF6E8, #FFFFFF, #FFFBF7, #FFCFA3)" 
                    : "white", // Default background
                color: activeCategory === category ? "#63676B" : "#63676B", 
              }}
            >
              {category === "All" ? category : categoryDisplayNames[category]}
            </button>
          ))}
        </div>

        <Row className="g-4">
          {filteredPosts.length > 0 ? (
            <>
              {/* Show the first blog in a full row */}
              {filteredPosts.slice(0, 1).map((post) => (
                <Col key={post.id} xs={12}>
                  <Card className="h-100 shadow-sm hover-shadow">
                    {/* <img
              src={post.image.startsWith("/") ? post.image : "https://i.imghippo.com/files/PGQm1184zmM.png"}
              alt="Blog"
            /> */}
                    <img src="https://i.imghippo.com/files/PGQm1184zmM.png" />
                    <CardContent>
                      <Typography variant="h6" component="h2" gutterBottom>
                        {post.title}
                      </Typography>
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        className="mb-2"
                        dangerouslySetInnerHTML={{
                          __html: post.description.substring(0, 150) + "...",
                        }}
                      />
                      <Typography
                        variant="subtitle2"
                        color="text.secondary"
                        gutterBottom
                      >
                        {new Date(post.created_at).toLocaleString("en-US", {
                          year: "numeric",
                          month: "long",
                          day: "numeric",
                        })}
                      </Typography>
                      <button
                        onClick={() => handleReadMore(post.id)}
                        className=" border-0 bg-transparent p-0"
                        style={{ cursor: "pointer", color: "#F69E1F" }}
                      >
                        Read more
                      </button>
                    </CardContent>
                  </Card>
                </Col>
              ))}

              {/* Show the remaining blogs in rows of 3 */}
              {filteredPosts.slice(1).map((post, index) => (
                <React.Fragment key={post.id}>
                  {index % 3 === 0 && <div className="w-100"></div>}
                  <Col xs={12} md={6} lg={4}>
                    <Card className="h-100 shadow-sm hover-shadow flex-blog">
                      {/* <img
                src={post.image.startsWith("/") ? post.image : "https://i.imghippo.com/files/PGQm1184zmM.png"}
                alt="Blog"
              /> */}
                      <img src="https://i.imghippo.com/files/PGQm1184zmM.png" />
                      <div></div>
                      <CardContent>
                        <Typography variant="h6" component="h2" gutterBottom>
                          {post.title}
                        </Typography>
                        <Typography
                          variant="body2"
                          color="text.secondary"
                          className="mb-2"
                          dangerouslySetInnerHTML={{
                            __html: post.description.substring(0, 150) + "...",
                          }}
                        />
                        <Typography
                          variant="subtitle2"
                          color="text.secondary"
                          gutterBottom
                        >
                          {new Date(post.created_at).toLocaleString("en-US", {
                            year: "numeric",
                            month: "long",
                            day: "numeric",
                          })}
                        </Typography>
                        <button
                          onClick={() => handleReadMore(post.id)}
                          className=" border-0 bg-transparent p-0"
                          style={{ cursor: "pointer", color: "#F69E1F" }}
                        >
                          Read more
                        </button>
                      </CardContent>
                    </Card>
                  </Col>
                </React.Fragment>
              ))}
            </>
          ) : (
            <Col xs={12}>
              <Typography variant="h6" className="text-center py-4">
                No posts found for this category
              </Typography>
            </Col>
          )}
        </Row>
      </Container>
    </div>
  );
};

export default Blog;
